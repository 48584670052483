import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "PaaS を Heroku から AWS Elastic Beanstalk に移行した話",
  "date": "2017-09-22T03:40:00.000Z",
  "slug": "entry/2017/09/22/124000",
  "tags": ["medley"],
  "hero": "./2017_09_22.png",
  "heroAlt": "beanstalk"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の宮内です。`}</p>
    <p>{`さて、これまで弊社のオンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」では、ローンチ時より`}<a parentName="p" {...{
        "href": "https://www.heroku.com/"
      }}>{`Heroku`}</a>{`を利用しておりました。
Heroku とは、PaaS の一種で Web アプリケーションを簡単にデプロイ、ホスティングできるサービスです。
ある程度の制約はつきますが、(大体の制約は金で解決できるので)使っているかたも多いのではないでしょうか。`}</p>
    <p>{`今回、事情により Heroku から`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/elasticbeanstalk/"
      }}>{`AWS Elastic Beanstalk`}</a>{`(以下、EB)へ移行することになりましたので、そのあたりでやったことを共有できればと思います。`}</p>
    <h1>{`Private PaaS にしないの？`}</h1>
    <p>{`まずはじめに移行にあたり、Priavte PaaS を構築する方法を模索しました。
ですが、これらのクラスタの構築はできても、専任の(Ops|SRE|インフラ)チームがいないため、日々の管理や運用に手が回らないだろうという思いから、Private PaaS の構築は見送りました。
この辺りは今後チーム人数が増えたら挑戦していきたいです…。`}</p>
    <h2>{`検討時、参考にしたリンク`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.jancarloviray.com/blog/paas-comparison-2017-dokku-flynn-deis-kubernetes-docker-swarm/"
        }}>{`PAAS comparison - Dokku vs Flynn vs Deis vs Kubernetes vs Docker Swarm (2017)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://convox.com/"
        }}>{`Convox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://deis.io/"
        }}>{`Deis`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flynn.io/"
        }}>{`Flynn`}</a></li>
    </ul>
    <h1>{`なぜ AWS Elastic Beanstalk にしたの？`}</h1>
    <p>{`EB には Rails や Sinatra で作成されたウェブアプリケーションを実行するための`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/ja_jp/elasticbeanstalk/latest/dg/create_deploy_Ruby.html"
      }}>{`Ruby プラットフォーム`}</a>{`が予め用意されております。
ただし、今回の移行では、アプリケーションへの変更を一切加えずに行いたかったため、Ruby プラットフォームを利用しませんでした。
代わりに Docker コンテナが実行できる`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/ja_jp/elasticbeanstalk/latest/dg/create_deploy_docker.html"
      }}>{`プラットフォーム`}</a>{`があったため、そちらを使うことにしました。`}</p>
    <h1>{`herokuish で Docker イメージを作成`}</h1>
    <p>{`アプリケーションの Docker イメージ化には、`}<a parentName="p" {...{
        "href": "https://github.com/gliderlabs/herokuish"
      }}>{`gliderlabs/herokuish`}</a>{`を使いました。
これは`}<a parentName="p" {...{
        "href": "https://devcenter.heroku.com/articles/buildpacks"
      }}>{`buildpack`}</a>{`を使いアプリケーションを slug 化したり、slug を実行するためのツールです。`}</p>
    <p>{`Docker イメージ作成の手順は以下の通りです。`}</p>
    <ol>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`herokuish buildpack build`}</code>{` と `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`herokuish slug generate`}</code>{` でアプリケーションを slug にする`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`herokuish slug import`}</code>{`で slug をインポートして完成`}</li>
    </ol>
    <p>{`それでは、それぞれ簡単に説明していきたいと思います。`}</p>
    <h1>{`アプリケーションを slug にする`}</h1>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` pull gliderlabs/herokuish
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -v `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/path/to/app:/tmp/build"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -v `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/path/to/cache:/tmp/cache"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -v `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/path/to/slug:/tmp/slug"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  gliderlabs/herokuish `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  /bin/bash -c `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'herokuish buildpack build && herokuish slug generate && herokuish slug export > /tmp/slug/slug.tgz'`}</span></code></pre></div>
    <p>{`herokuish は特定のディレクトリに対して処理を行います。↑ ではビルドするアプリケーションまでのディレクトリパスを`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/tmp/build`}</code>{`にマッピングしています。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`/tmp/cache`}</code>{`は buildpack が利用するキャッシュ置き場です。このディレクトリを次回以降のビルドでもマッピングしておくとビルドの高速化が見込めます。`}</p>
    <p>{`最後の`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/tmp/slug`}</code>{`はビルドした slug をコンテナからホストへコピーするために指定しています。(これは herokuish で用意されてるものではなくコンテナからホストへファイルをコピーする方法を悩んだ末のアドホックな対応です…)`}</p>
    <p>{`他にも様々なディレクトリがあります。詳しくは`}<a parentName="p" {...{
        "href": "https://github.com/gliderlabs/herokuish#paths"
      }}>{`ドキュメント`}</a>{`をご覧ください。`}</p>
    <h1>{`slug をインポートする`}</h1>
    <p>{`次に作成した slug を使いアプリケーションの Docker イメージをつくります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span>{`mktemp -d`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`mv`}</span>{` /path/to/slug/slug.tgz `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'
FROM gliderlabs/herokuish

COPY slug.tgz /tmp/slug.tgz
RUN cat /tmp/slug.tgz | herokuish slug import && rm /tmp/slug.tgz

EXPOSE 5000
'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` Dockerfile
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` build -f Dockerfile `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span></code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`docker build`}</code>{`時に Context としてカレントディレクトリ全体が送られるため、一時ディレクトリを作成しその中で`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker build`}</code>{`を行っています。`}</p>
    <h1>{`終わり`}</h1>
    <p>{`CLINICS では上記のような手段で作成した Docker イメージを`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/ecr/"
      }}>{`Amazon EC2 Container Registry`}</a>{`にアップロードし EB 上で実行しています。`}</p>
    <p>{`本来であれば、アプリケーションを slug にする部分と、slug をインポートする部分を分割しなくても良いと思いますが、CircleCI で Docker イメージを作成する関係上でこのような方法になりました。`}</p>
    <p>{`先日 GA となった CircleCI 2.0 にはまだ対応できていないので、今後の課題としたいと思います。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、CLINICS だけでなく、医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」、医師たちがつくるオンライン医療事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」、口コミで探せる介護施設の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」などのプロダクトも提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>
    <p>{`メドレーで一緒に医療体験を変えるプロダクト作りに関わりたい方のご連絡お待ちしております。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      